export default ({
    colors,
    spaces,
}) => ({
    key: { name: 'Player' },

    player: {
        width: '100%',
        height: '100%',
        paddingTop: spaces[2],
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 12,
        backgroundColor: 'rgba(0, 0, 0, .85)',
        cursor: 'pointer',
    },

    inner: {
        width: '90vw',
		height: '50.625vw',
		maxHeight: '90vh',
		maxWidth: '160vh',
		margin: 'auto',
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
        backgroundColor: colors.black,
        cursor: 'auto',
    },

    video: {
        position: 'absolute',
        top: 0,
        left: 0,
    },

    close: {
        position: 'absolute',
        top: spaces[2],
        right: spaces[2],
    },
})
