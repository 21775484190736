import React, { useRef, useState, useEffect } from 'react'
import cx from 'classnames'
import is from 'react-jss'

import Link from '../Link'
import IconButton from '../IconButton'
import Button from '../Button'
import Player from '../Player'
import Smoke from '../Smoke'

import style from './style'

const ImageHero = ({ classes, discordLabel, socialLinks, subscribeLabel }) => {
    const heroRef = useRef()
    const [showPlayer, setShowPlayer] = useState(false)
    const [loaded, setLoaded] = useState(false)

    const handleSubscribeClick = () => {
        if (heroRef?.current && typeof window !== 'undefined') {
            const { y, height } = heroRef.current.getBoundingClientRect()

            window.scroll({ left: 0, top: y + height, behavior: 'smooth' })
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setLoaded(true)
        }, 75)
    }, [])

    const handleDiscordClick = () => {
        window.dataLayer.push({
            event: 'event',
            eventProps: {
                category: 'TTO',
                action: 'Click',
                label: 'JoinOnDiscord',
            },
        })
    }

    return (
        <div ref={heroRef} className={cx(classes.hero, { loaded })}>
            <div className={classes.background}>
                <div className={classes.backgroundImages}>
                    <div className={classes.backgroundImage} />
                    <div className={classes.backgroundImage} />
                    <div className={classes.backgroundImage} />
                    <div className={classes.backgroundImage} />
                </div>

                <Smoke className={classes.smoke} />
            </div>

            <div className={cx(classes.col, classes.socialLinks)}>
                {socialLinks
                    ?.filter(({ type }) => type === 'Discord')
                    ?.map(({ to }, i) => (
                        <Link
                            {...{ key: i, to, external: true }}
                            clickHandler={() => handleDiscordClick()}
                            className={classes.socialLink}
                        >
                            <IconButton
                                iconSize="lg"
                                name="discord"
                                classNames={{ btn: classes.socialLinkIcon }}
                            />
                            <span className={classes.socialLinkLabel}>
                                {discordLabel}
                            </span>
                        </Link>
                    ))}
            </div>

            <div className={cx(classes.col, classes.subscribe)}>
                <div className={classes.subscribeInner}>
                    <Button
                        clickHandler={() => handleSubscribeClick()}
                        className={classes.subscribeBtn}
                    >
                        {subscribeLabel}
                    </Button>
                    <span className={classes.subscribeBar} />
                </div>
            </div>

            {showPlayer && (
                <Player
                    {...{
                        url: 'https://youtu.be/WVQZTCQyZt8',
                        closeHandler: () => setShowPlayer(false),
                    }}
                />
            )}

            <div className={cx(classes.col, classes.right)}>
                <div className={classes.rightInner}>
                    <div className={classes.embed}>
                        <iframe
                            src="https://store.steampowered.com/widget/377300/?t=Thunder%20Tier%20One%20is%20a%20realistic%20top-down%20shooter%20where%20you%E2%80%99ll%20join%20an%20elite%20task%20force%20fighting%20against%20a%20terrorist%20organization%20sweeping%20its%20way."
                            title="Steam"
                            frameBorder="0"
                            width="100%"
                            height="190"
                            className={classes.embedIframe}
                        ></iframe>
                    </div>
                    <div className={classes.trailer}>
                        <div
                            className={classes.trailerInner}
                            onClick={() => setShowPlayer(true)}
                        >
                            <IconButton
                                name="play"
                                size="lg"
                                iconSize="lg"
                                classNames={{ btn: classes.trailerBtn }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default is(style)(ImageHero)
