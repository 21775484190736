import React from 'react'
import cx from 'classnames'
import ReactPlayer from 'react-player'
import is from 'react-jss'

import IconButton from '../IconButton'

import style from './style'

const StyledComponent = ({
    classes,
    url = null,
    closeHandler = null,
    classNames = {},
}) => {
    const handleClose = (e) => {
        e.stopPropagation()
        closeHandler()
    }

    return url && (
        <div className={ cx(classes.player, classNames.player) } onClick={ (e) => typeof closeHandler === 'function' && handleClose(e) }>
            <div className={ cx(classes.inner, classNames.inner) }>
                <ReactPlayer
                    { ...{
                        width: '100%',
                        height: '100%',
                        url,
                        playing: true,
                        autoplay: true,
                        controls: true,
                        muted: false,
                        className: cx(classes.video, classNames.video),
                    } }
                />
            </div>

            <IconButton
                name="close"
                iconSize="sm"
                size="sm"
                color="white"
                classNames={{ btn: classes.close }}
                clickHandler={ () => handleClose() }
            />
        </div>
    )
}

export default is(style)(StyledComponent)
