export default ({
}) => ({
    key: { name: 'Smoke' },

    smoke: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        bottom: 0,
        left: 0,
        opacity: 1,
        transform: 'translate3d(0, 0, 0)',
        pointerEvents: 'none',
    },
})
