export default ({
    spaces,
    colors,
    breakpoints,
    fontstacks,
    typography,
}) => ({
    key: { name: 'ImageHero' },

    hero: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        minHeight: '100vh',
        position: 'relative',
        backgroundColor: colors.black,
        textAlign: 'center',
        overflow: 'hidden',

        [breakpoints.sm]: {
            justifyContent: 'flex-end',
        },

        [breakpoints.lg]: {
            flexDirection: 'row',
            alignItems: 'flex-end',
            padding: [110+spaces[3], spaces[3], spaces[3]],
        },
    },

    background: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        width: '100%',
        paddingTop: '64%',
        position: 'relative',
        transformOrigin: 'center',
        opacity: 0,
        visibility: 'hidden',
        transition: 'opacity ease-in-out .4s, visibility ease-in-out .4s',
        textAlign: 'center',

        [breakpoints.lg]: {
            width: '100%',
            height: '100%',
            minHeight: 1,
            paddingTop: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            transform: 'scale(1.1)',
            transition: 'all ease-in-out .4s',

            '.loaded &': {
                transform: 'scale(1)',
            },
        },

        '.loaded &': {
            opacity: 1,
            visibility: 'visible',
        },
    },

        backgroundImages: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 0,
        },

            backgroundImage: {
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                backgroundImage: 'url(/hero-mobile.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',

                [breakpoints.md]: {
                    backgroundImage: 'url(/hero.jpg)',
                },

                '&:nth-child(1)': {
                    opacity: 1,
                },
                  
                '&:nth-child(2)': {
                    backgroundColor: 'red',
                    backgroundBlendMode: 'normal',
                },

                '&:nth-child(3)': {
                    backgroundColor: 'green',
                    backgroundBlendMode: 'normal',
                },

                '&:nth-child(4)': {
                    backgroundColor: 'blue',
                    backgroundBlendMode: 'normal',
                },

                '&:nth-child(n+2)': {
                    opacity: 0,
                },

                '&:nth-child(n+1)': {
                    opacity: 1,
                },

                '&:nth-child(2)': {
                    transform: 'translate3d(1.1, 0, 0)',
                    animation: '$glitch-horizontal 5s infinite linear alternate',
                },

                '&:nth-child(3)': {
                    transform: 'translate3d(-1.1, 0, 0)',
                    animation: '$glitch-horizontal-2 4s infinite linear alternate',
                },

                '&:nth-child(4)': {
                    transform: 'translate3d(-1.1, 0, 0)',
                    animation: '$glitch-vertical 2s infinite linear alternate',
                },
            },

    col: {
        width: '100%',
        padding: [spaces[4], spaces[3]],
        position: 'relative',
        zIndex: 3,

        [breakpoints.md]: {
            padding: spaces[4],
        },

        [breakpoints.lg]: {
            flex: 1,
            width: 'auto',
            marginTop: 0,
            padding: 0,

            '&:last-child': {
                textAlign: 'right',
            },
        },
    },

    socialLinks: {
        order: 3,
        textAlign: 'center',
        backgroundColor: colors.white,

        [breakpoints.mw.md]: {
            padding: spaces[2],
        },
        
        [breakpoints.lg]: {
            order: 0,
            textAlign: 'left',
            backgroundColor: 'transparent',
        },

        '&:hover': {
            '& $socialLinkIcon': {
                backgroundColor: `${colors.white}!important`,

                '& .icon-fill': {
                    fill: `${colors.black}!important`,
                },
            },
        },
    },

    socialLinkIcon: {},

    socialLink: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: spaces[1],
        textAlign: 'left',

        '&:first-child': {
            marginLeft: 0,
        },
    },

    socialLinkLabel: {
        marginLeft: spaces[1],
        textTransform: 'uppercase',
        ...typography.lg,
        ...fontstacks.secondary,
        '-webkit-mask-image': 'url(/texture-mask2.png)',
        fontWeight: 700,
        color: colors.black,

        [breakpoints.lg]: {
            color: colors.white,
        },
    },

    subscribe: {
        display: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        ...fontstacks.secondary,
        textTransform: 'uppercase',
        textAlign: 'center',

        [breakpoints.md]: {
            display: 'block',
        },
    },

    subscribeInner: {
        display: 'inline-block',
        position: 'relative',

        '&:hover': {
            '& $subscribeBtn': {
                backgroundColor: colors.black,
                color: colors.white,
            },

            '& $subscribeBar': {
                transform: 'translate3d(0, 100%, 0)',
            },
        },
    },

    subscribeBtn: {
        backgroundColor: 'transparent',
        color: colors.white,
        '-webkit-mask-image': 'url(/texture-mask2.png)',
    },

    subscribeBar: {
        display: 'none',
        width: 2,
        height: spaces[3],
        marginLeft: -1,
        position: 'absolute',
        top: '100%',
        left: '50%',
        backgroundColor: colors.white,
        transition: 'all ease-in-out .175s',
        '-webkit-mask-image': 'url(/texture-mask1.png)',

        [breakpoints.lg]: {
            display: 'block',
        },
    },

    right: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        position: 'relative',
        backgroundColor: colors.black,
        backgroundImage: 'url(/black-glitch.jpg)',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',

        [breakpoints.lg]: {
            flex: 1,
            background: 'transparent',
        },
    },

    rightInner: {
        display: 'block',
        width: '100%',

        [breakpoints.md]: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },

        [breakpoints.lg]: {
            display: 'block',
            maxWidth: 440,
        },
    },

    embed: {
        width: '100%',
        position: 'relative',
        transform: `translate3d(${spaces[2]}px, 0, 0)`,
        opacity: 0,
        visibiity: 'hidden',
        transition: 'all ease-in-out .4s',
        transitionDelay: '.25s',

        '.loaded &': {
            transform: 'translate3d(0, 0, 0)',
            opacity: 1,
            visibiity: 'visible',
        },
    },

    embedIframe: {
        margin: [0, 'auto'],

        [breakpoints.lg]: {
            maxWidth: 440,
        },
    },

    trailer: {
        width: '100%',
        margin: [spaces[3], 'auto', 0],
        transform: `translate3d(${spaces[2]}px, 0, 0)`,
        opacity: 0,
        visibiity: 'hidden',
        transition: 'all ease-in-out .4s',
        transitionDelay: '.5s',

        '.loaded &': {
            transform: 'translate3d(0, 0, 0)',
            opacity: 1,
            visibiity: 'visible',
        },

        [breakpoints.md]: {
            margin: [0, 0, 0, spaces[3]],
        },

        [breakpoints.lg]: {
            maxWidth: 440,
            margin: [spaces[3], 0, 0],
        },
    },

    trailerInner: {
        paddingTop: '56.25%',
        position: 'relative',
        backgroundColor: colors.silverEdge,
        backgroundImage: 'url(/TTO-launch-thumbnail.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer',

        '&:hover': {
            '& $trailerBtn': {
                backgroundColor: `${colors.white}!important`,

                '& .icon-fill': {
                    fill: `${colors.black}!important`,
                },
            },
        },
    },

    trailerBtn: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate3d(-50%, -50%, 0)',
    },

    '@keyframes glitch-horizontal': {
        '0%': { 
            '-webkit-clip-path': 'polygon(0 2%, 100% 2%, 100% 5%, 0 5%)',
            clipPath: 'polygon(0 2%, 100% 2%, 100% 5%, 0 5%)',
        },
        '10%': {
            '-webkit-clip-path': 'polygon(0 15%, 100% 15%, 100% 15%, 0 15%)',
            clipPath: 'polygon(0 15%, 100% 15%, 100% 15%, 0 15%)',
        },
        '20%': {
            '-webkit-clip-path': 'polygon(0 10%, 100% 10%, 100% 20%, 0 20%)',
            clipPath: 'polygon(0 10%, 100% 10%, 100% 20%, 0 20%)',
        },
        '30%': {
            '-webkit-clip-path': 'polygon(0 1%, 100% 1%, 100% 2%, 0 2%)',
            clipPath: 'polygon(0 1%, 100% 1%, 100% 2%, 0 2%)',
        },
        '40%': {
            '-webkit-clip-path': 'polygon(0 33%, 100% 33%, 100% 33%, 0 33%)',
            clipPath: 'polygon(0 33%, 100% 33%, 100% 33%, 0 33%)',
        },
        '50%': {
            '-webkit-clip-path': 'polygon(0 44%, 100% 44%, 100% 44%, 0 44%)',
            clipPath: 'polygon(0 44%, 100% 44%, 100% 44%, 0 44%)',
        },
        '60%': {
            '-webkit-clip-path': 'polygon(0 50%, 100% 50%, 100% 20%, 0 20%)',
            clipPath: 'polygon(0 50%, 100% 50%, 100% 20%, 0 20%)',
        },
        '70%': {
            '-webkit-clip-path': 'polygon(0 70%, 100% 70%, 100% 70%, 0 70%)',
            clipPath: 'polygon(0 70%, 100% 70%, 100% 70%, 0 70%)',
        },
        '80%': {
            '-webkit-clip-path': 'polygon(0 80%, 100% 80%, 100% 80%, 0 80%)',
            clipPath: 'polygon(0 80%, 100% 80%, 100% 80%, 0 80%)',
        },
        '90%': {
            '-webkit-clip-path': 'polygon(0 50%, 100% 50%, 100% 55%, 0 55%)',
            clipPath: 'polygon(0 50%, 100% 50%, 100% 55%, 0 55%)',
        },
        '100%': {
            '-webkit-clip-path': 'polygon(0 70%, 100% 70%, 100% 80%, 0 80%)',
            clipPath: 'polygon(0 70%, 100% 70%, 100% 80%, 0 80%)',
        },
    },
    
    '@keyframes glitch-horizontal-2': {
        '0%': { 
            opacity: 1,
            transform: 'translate3d(-10px, 0, 0)',
            '-webkit-clip-path': 'polygon(0 25%, 100% 25%, 100% 30%, 0 30%)',
            clipPath: 'polygon(0 25%, 100% 25%, 100% 30%, 0 30%)',
        },
        '3%': {
            '-webkit-clip-path': 'polygon(0 3%, 100% 3%, 100% 3%, 0 3%)',
            clipPath: 'polygon(0 3%, 100% 3%, 100% 3%, 0 3%)',
        },
        '5%': {
            '-webkit-clip-path': 'polygon(0 5%, 100% 5%, 100% 20%, 0 20%)',
            clipPath: 'polygon(0 5%, 100% 5%, 100% 20%, 0 20%)',
        },
        '7%': {
            '-webkit-clip-path': 'polygon(0 20%, 100% 20%, 100% 20%, 0 20%)',
            clipPath: 'polygon(0 20%, 100% 20%, 100% 20%, 0 20%)',
        },
        '9%': {
            '-webkit-clip-path': 'polygon(0 40%, 100% 40%, 100% 40%, 0 40%)',
            clipPath: 'polygon(0 40%, 100% 40%, 100% 40%, 0 40%)',
        },
        '11%': {
            '-webkit-clip-path': 'polygon(0 52%, 100% 52%, 100% 59%, 0 59%)',
            clipPath: 'polygon(0 52%, 100% 52%, 100% 59%, 0 59%)',
        },
        '13%': {
            '-webkit-clip-path': 'polygon(0 60%, 100% 60%, 100% 60%, 0 60%)',
            clipPath: 'polygon(0 60%, 100% 60%, 100% 60%, 0 60%)',
        },
        '15%': {
            '-webkit-clip-path': 'polygon(0 75%, 100% 75%, 100% 75%, 0 75%)',
            clipPath: 'polygon(0 75%, 100% 75%, 100% 75%, 0 75%)',
        },
        '17%': {
            '-webkit-clip-path': 'polygon(0 65%, 100% 65%, 100% 40%, 0 40%)',
            clipPath: 'polygon(0 65%, 100% 65%, 100% 40%, 0 40%)',
        },
        '19%': {
            '-webkit-clip-path': 'polygon(0 45%, 100% 45%, 100% 50%, 0 50%)',
            clipPath: 'polygon(0 45%, 100% 45%, 100% 50%, 0 50%)',
        },
        '20%': {
            '-webkit-clip-path': 'polygon(0 14%, 100% 14%, 100% 33%, 0 33%)',
            clipPath: 'polygon(0 14%, 100% 14%, 100% 33%, 0 33%)',
        },
        '21.9%': {
            opacity: 1,
            transform: 'translate3d(-10px, 0, 0)',
        },
        '22%, 100%': {
            opacity: 0,
            transform: 'translate3d(0,0,0)',
            '-webkit-clip-path': 'polygon(0 0, 0 0, 0 0, 0 0)',
            clipPath: 'polygon(0 0, 0 0, 0 0, 0 0)',
        },
    },
    
    '@keyframes glitch-vertical': {
        '0%': { 
            opacity: 1,
            transform: 'translate3d(0, -5px, 0) scale3d(-1,-1,1)',
            '-webkit-clip-path': 'polygon(0 1%, 100% 1%, 100% 3%, 0 3%)',
            clipPath: 'polygon(0 1%, 100% 1%, 100% 3%, 0 3%)',
        },
        '1.5%': {
            '-webkit-clip-path': 'polygon(0 10%, 100% 10%, 100% 9%, 0 9%)',
            clipPath: 'polygon(0 10%, 100% 10%, 100% 9%, 0 9%)',
        },
        '2%': {
            '-webkit-clip-path': 'polygon(0 5%, 100% 5%, 100% 6%, 0 6%)',
            clipPath: 'polygon(0 5%, 100% 5%, 100% 6%, 0 6%)',
        },
        '2.5%': {
            '-webkit-clip-path': 'polygon(0 20%, 100% 20%, 100% 20%, 0 20%)',
            clipPath: 'polygon(0 20%, 100% 20%, 100% 20%, 0 20%)',
        },
        '3%': {
            '-webkit-clip-path': 'polygon(0 10%, 100% 10%, 100% 10%, 0 10%)',
            clipPath: 'polygon(0 10%, 100% 10%, 100% 10%, 0 10%)',
        },
        '5%': {
            '-webkit-clip-path': 'polygon(0 30%, 100% 30%, 100% 25%, 0 25%)',
            clipPath: 'polygon(0 30%, 100% 30%, 100% 25%, 0 25%)',
        },
        '5.5%': {
            '-webkit-clip-path': 'polygon(0 15%, 100% 15%, 100% 16%, 0 16%)',
            clipPath: 'polygon(0 15%, 100% 15%, 100% 16%, 0 16%)',
        },
        '7%': {
            '-webkit-clip-path': 'polygon(0 40%, 100% 40%, 100% 39%, 0 39%)',
            clipPath: 'polygon(0 40%, 100% 40%, 100% 39%, 0 39%)',
        },
        '8%': {
            '-webkit-clip-path': 'polygon(0 20%, 100% 20%, 100% 21%, 0 21%)',
            clipPath: 'polygon(0 20%, 100% 20%, 100% 21%, 0 21%)',
        },
        '9%': {
            '-webkit-clip-path': 'polygon(0 60%, 100% 60%, 100% 55%, 0 55%)',
            clipPath: 'polygon(0 60%, 100% 60%, 100% 55%, 0 55%)',
        },
        '10.5%': {
            '-webkit-clip-path': 'polygon(0 30%, 100% 30%, 100% 31%, 0 31%)',
            clipPath: 'polygon(0 30%, 100% 30%, 100% 31%, 0 31%)',
        },
        '11%': {
            '-webkit-clip-path': 'polygon(0 70%, 100% 70%, 100% 69%, 0 69%)',
            clipPath: 'polygon(0 70%, 100% 70%, 100% 69%, 0 69%)',
        },
        '13%': {
            '-webkit-clip-path': 'polygon(0 40%, 100% 40%, 100% 41%, 0 41%)',
            clipPath: 'polygon(0 40%, 100% 40%, 100% 41%, 0 41%)',
        },
        '14%': {
            '-webkit-clip-path': 'polygon(0 80%, 100% 80%, 100% 75%, 0 75%)',
            clipPath: 'polygon(0 80%, 100% 80%, 100% 75%, 0 75%)',
        },
        '14.5%': {
            '-webkit-clip-path': 'polygon(0 50%, 100% 50%, 100% 51%, 0 51%)',
            clipPath: 'polygon(0 50%, 100% 50%, 100% 51%, 0 51%)',
        },
        '15%': {
            '-webkit-clip-path': 'polygon(0 90%, 100% 90%, 100% 90%, 0 90%)',
            clipPath: 'polygon(0 90%, 100% 90%, 100% 90%, 0 90%)',
        },
        '16%': {
            '-webkit-clip-path': 'polygon(0 60%, 100% 60%, 100% 60%, 0 60%)',
            clipPath: 'polygon(0 60%, 100% 60%, 100% 60%, 0 60%)',
        },
        '18%': {
            '-webkit-clip-path': 'polygon(0 100%, 100% 100%, 100% 99%, 0 99%)',
            clipPath: 'polygon(0 100%, 100% 100%, 100% 99%, 0 99%)',
        },
        '20%': {
            '-webkit-clip-path': 'polygon(0 70%, 100% 70%, 100% 71%, 0 71%)',
            clipPath: 'polygon(0 70%, 100% 70%, 100% 71%, 0 71%)',
        },
        '21.9%': {
            opacity: 1,
            transform: 'translate3d(0, -10px, 0) scale3d(-1,-1,1)',
        },
        '22%, 100%': {
            opacity: 0,
            transform: 'translate3d(0,0,0)',
            '-webkit-clip-path': 'polygon(0 0, 0 0, 0 0, 0 0)',
            clipPath: 'polygon(0 0, 0 0, 0 0, 0 0)',
        },
    },
    
    '@keyframes glitch-flash': {
        '0%': { 
            opacity: 0.2, 
            transform: 'translate3d(10px, 5px, 0)',
        },
        '33%, 100%': { 
            opacity: 0,
            transform: 'translate3d(0, 0, 0)',
        },
    },
    
    '@keyframes glitch-flash-2': {
        '0%': { 
            opacity: 0.2, 
            transform: 'translate3d(5px, 10px, 0)',
        },
        '13%, 100%': { 
            opacity: 0,
            transform: 'translate3d(0, 0, 0)',
        },
    },
})
