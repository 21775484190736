import React, { useEffect, useRef } from 'react'
import is from 'react-jss'

import Smoke from './smoke'

import style from './style'

const SmokeEffect = ({
    classes,
}) => {
    const canvasRef = useRef()

    useEffect(() => {
        if (canvasRef?.current && typeof window !== 'undefined') {
            let canvas = document.getElementById('smoke')
            let context = canvas.getContext('2d')
    
            canvas.width = 1500
            canvas.height = 1000
    
            let smoke = new Smoke(context, [200, 200, 200])
            smoke.start()
            addSmoke(smoke)
    
            // Make run smoke run indefinitely
            function addSmoke() {
                smoke.addSmoke(0, 1000, 4)
                smoke.addSmoke(window.innerWidth*.25, 880, 2)
                smoke.addSmoke(window.innerWidth*.5, 1000, 1)
                smoke.addSmoke(window.innerWidth*.75, 1000, 2)
                smoke.addSmoke(window.innerWidth, 950, 5)
    
                setTimeout(() => {
                    addSmoke()
                }, 1000)
            }
        }
    }, [])

    return (
        <canvas
            ref={canvasRef}
            id="smoke"
            className={classes.smoke}
            width="100vw"
            height="100vh"
        />
    )
}

export default is(style)(SmokeEffect)
